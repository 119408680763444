import React from 'react';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { downloadURI } from 'utils';

function About() {
  const { language } = useI18next();
  const data = useStaticQuery(
    graphql`
        query ProgramDocument {
            en: file(extension: {eq: "pdf"}, name: {eq: "brochure_ru"}) {
                publicURL
            }
            et: file(extension: {eq: "pdf"}, name: {eq: "didah_et"}) {
                publicURL
            }
            ru: file(extension: {eq: "pdf"}, name: {eq: "brochure_ru"}) {
                publicURL
            }
        }
    `,
  );

  return (
    <Container
      fluid="lg"
      className="section"
    >
      <Row>
        <Col lg="6">
          <Row>
            <Col sm="11">
              <h2>
                <Trans>about-main-header</Trans>
              </h2>
              <div className="my-4">
                <Button
                  size="lg"
                  variant="outline-primary"
                  type="button"
                  onClick={() => downloadURI(data[language].publicURL)}
                >
                  &darr;&nbsp;
                  <Trans>about-main-button</Trans>
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="6">
              <h4>
                <Trans>about-1-header</Trans>
              </h4>
              <p>
                <Trans>about-1</Trans>
              </p>
            </Col>
            <Col lg="6">
              <h4>
                <Trans>about-2-header</Trans>
              </h4>
              <p>
                <Trans>about-2</Trans>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export {
  About,
};
